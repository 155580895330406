<template>
  <div class="GroupWork" v-cloak>
     <div class="top">
       <img :src="detila.course_image" alt="">
     </div>
     <!-- 卡片 -->
    <div class="course-card">
      <div class="title-head">
        <van-row>
          <van-col span="12">{{detila.course_name}}</van-col>
          <van-col span="12" style="text-align:right">
            <div class="photo"  @click='rules'>
                 拼团规则
            </div>
          </van-col>
        </van-row>
      </div>
      <div class="money">
        <div class="title-head-tag" >￥{{group_course.discount_price}}</div>
        <div class="title-head-tag" style="text-decoration:line-through;color:#8C98B3">￥{{group_course.original_price}}</div>
      </div>
      <div class="course-time">
        <van-row>
          <van-col span="12">{{detila.start_time.slice(0,10).replace(/-/g,'.')}}-{{detila.end_time.slice(0,10).replace(/-/g,'.')}}</van-col>
          <van-col span="12" style="text-align:right;font-size:0.32rem;color:#8c98b3">{{detila.period_total}}课时</van-col>
        </van-row>
      </div>
    </div>
    <!-- 拼团倒计时 -->
    <div class="countDown">
      <div class="top">
        <p v-if='detila.is_buy==1'>您已购买过此课程</p>
        <p v-if='detila.group_user==1'>您已拼团成功，距拼团结束</p>
        <p v-if='groups.status==0&&detila.group_user==0&&detila.is_buy==0'>距拼团结束</p>
        <p v-if='groups.status==1'>拼团已结束</p>
        <div class="time" >
         <div v-if='groups.status==0'>{{date_h}}</div>
         <div v-if='groups.status==1'>0</div>
         <img src="../../assets/times.png" alt="">
         <div v-if='groups.status==0'>{{date_m}}</div>
         <div v-if='groups.status==1'>0</div>
          <img src="../../assets/times.png" alt="">
         <div v-if='groups.status==0'>{{date_s}}</div>
         <div v-if='groups.status==1'>0</div>

        </div>
      </div>
      <div class="bottom">
        <p  v-if='groups.status==0'>还差<span>{{groups.vacancy_number}}</span>人即可拼团成功</p>
        <p  v-if='groups.status==1'>团已拼成，请前往app查看更多拼团课程</p>
        <div class="photos">
          <div v-for='(item,i) in user_member'>
            <img :src='item.member.avatar'  alt="" >
           
          </div>
           <img  v-if='groups.status==0' src="../../assets/ptddd.png" alt="" class="dian">
        </div>
        <div class="btn" @click='signUp'  v-if='groups.status==0&&detila.group_user==0&&detila.is_buy==0'>
          参与拼团
        </div>
        <div class="btn" @click='jump'  v-if='detila.group_user==1'>
          邀请好友
        </div>
        <div class="btn"  v-if='groups.status==1||detila.is_buy==1'   @click="jump">
          前往APP
        </div>
      </div>

    </div>
    <!-- 拼团成功 -->
    <div class="countDown"  id='success'  v-if='detila.group_user_info.status==1&&detila.group_user==1'>
      <div class="bottom "  id='bottoms'>
        <p>恭喜您！拼团成功</p>
        <div class="photos">
          <img src="../../assets/touxiang.png" alt="">
          <img src="../../assets/ptddd.png" alt="" class="dian">
        </div>
        <div class="btn " id='btns' @click='download'>
          进入课程
        </div>
      </div>
    </div>
    <!-- 详情图 -->
    <div class="img">
      <img :src="detila.piiic" alt="">
    </div>
    <!-- 拼团规则 -->
    <div class="zhezhao" v-if='rule'   @click='rules'>  
      <div class="rule">
        <p>拼团规则</p>
        <img src="../../assets/guanbi.png" alt="">
        <div class="content">{{groups.event.remind}}</div>
     </div>
    </div>
  
  </div>
</template>
<script>
import { Toast } from 'vant';
import request from '../../plugin/request'
import Cookies from 'js-cookie';
import qs from "qs";
export default {
    data() {
    return {
      group_course:'',
      rule:false,
      course_id:'',
      date_h:'', 
      date_m:'',
      date_s:'',
      date_ms:'',
      detila:'',
      group_id:'',
      id:'',
      groups:'',
      user_member:'',
      times:'',
      discount_price:''

    };
  },
    mounted(){
    let currentQuery = this.$route.query;
    this.course_id=currentQuery.course_id
    this.group_id=currentQuery.group_id
    this.id=currentQuery.id
    this.setToken()
    this.detilas()
    this.groupdetila()
    this.$nextTick(() => {
     
    });
  },
  methods:{
    jumpa() {
       Toast('点击右上角"···"选择分享至朋友或朋友圈');  
    },
    jump() {
      this.$router.push({ path:'/download'})
    },
    //时间倒计时处理
    countTime() {
      // console.log(this.times)
      var date = new Date()
      var now = date.getTime()
      var end = parseInt(this.times) * 1000     //后台拿到的结束时间戳
      var leftTime = end - now //时间差
      var d, h, m, s, ms
      if (leftTime >= 0) {
        d = Math.floor(leftTime / 1000 / 60 / 60 / 24)
        h = Math.floor(((leftTime / 1000 / 60 / 60) % 24) + 24 * d)
        m = Math.floor((leftTime / 1000 / 60) % 60)
        s = Math.floor((leftTime / 1000) % 60)
        ms = Math.floor(leftTime % 1000)
        if (ms < 100) {
          ms = '0' + ms
        }
        if (s < 10) {
          s = '0' + s
        }
        if (m < 10) {
          m = '0' + m
        }
        // if(h < 10) {
        h =   h
        // }
      } else if(h=undefined) {
        // console.log('已截止')
      }
      //将倒计时赋值到div中
      this.date_d = d  //这里是渲染的天数
      this.date_h = h  //这里是渲染的小时
      this.date_m = m  //这里是渲染的分钟
      this.date_s = s  //这里是渲染的秒数
      this.date_ms = ms  //这里是渲染的毫秒
      //递归每秒调用countTime方法，显示动态时间效果
      setTimeout(this.countTime, 50)
      // console.log(h,m,s)
    },
    // 拼团规则
    rules() {
      this.rule=!this.rule
    },
    setToken(token) {
       var tokens = localStorage.getItem('token');
      //  console.log(tokens)
       if(tokens!=null) {
          return Cookies.set('Token', tokens)
       } else {
           return Cookies.set('Token', token)
       }
    },
    // 支付
    async  signUp() {
       var tokens = localStorage.getItem('token');
         var ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i)=="micromessenger") {
           Toast('点击右上角"···"选择用浏览器打开');   
      } else {
       if(tokens!=null) {
          this.$router.push({ path:'/payment?id='+this.course_id+'&url=GroupWork&group_com_id='+this.id+'&group=1&group_id='+this.group_id+'&discount_price='+this.discount_price})
        } else {
          Toast('请先登录！');  
          this.$router.push({ path:'/login?url=GroupWork&&id='+this.id+'&course_id='+this.course_id+'&group_id='+this.group_id})
       } }
      // this.download=true
    
    },
    // 下载
    download() {
      this.$router.push({ path:'/download'})
    },
    // 课程详情
    async detilas() {
      const data =await request.post('/app/course/details',{id:this.course_id})
      this.detila=data.data
      this.group_course=data.data.group.group_course
      // console.log(this.detila.group_user_info.status)
    },
    //拼团详情
    async groupdetila() {
      const data =await request.post('/app/group/details',{id:this.id,group_id:this.group_id})
      this.groups=data.data
      this.user_member=data.data.user_member
      console.log(data.data)
      this.times=data.data.end_time
      this.discount_price=data.data.discount_price
      // this.countTime(times)
            this.countTime()

    }
  }
};
</script>
<style lang="less" scoped>
.GroupWork {

  .top {
    width: 100%;
    height: 6.56rem;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .course-card {
    width: 9.47rem;
    height: 3.27rem;
    background: url(../../assets/ptxq.png);
    background-size: 100% 100%;
    margin:.27rem 0 0 .27rem;
    padding:.55rem .75rem;
    box-sizing: border-box;
    .title-head  {
  width: 100%;
  height: 0.65rem;
  line-height: 0.65rem;
  font-size: 0.45rem;
  font-weight: 600;
}

.photo  {
  width: 1.59rem;
  height:.55rem;
  border: .01rem solid #8C98B3;
  border-radius: .13rem;
  font-size: .32rem;
  font-weight: 500;
  color: #8C98B3;
  text-align: center;
  line-height: .55rem;
  float: right;
}

.money {
   height: 0.65rem;
  .title-head-tag  {
    float: right;
  height: 0.65rem;
  margin-top:0.13rem;
  line-height: 0.65rem;
  font-size: 0.37rem;
  color: #ff4a26;
  // float: right;
}
}


.course-time  {
  width: 100%;
  height:0.65rem;
  line-height: 0.65rem;
  font-size: 0.37rem;
}

  }

  #success {
    background: url(../../assets/ptxq.png);
    background-size: 100% 100%;
    width: 9.47rem!important;
    margin-left: .27rem;
    margin-top:.20rem;

    #bottoms {
      background-color:transparent!important;
      margin-left: 0;
    }

    #btns  {
      
      background: linear-gradient(110deg, #3A8CFE 0%, #2572FF 100%);   
    }

  }

  .countDown {
    width: 9.2rem;
    margin-bottom:.28rem;

     
     .top {
       width: 100%;
       height:1.96rem;
       background: url(../../assets/pttb.png);
       background-size: 100% 100%;
       margin-top: .4rem;
       overflow: hidden;
       margin-left: .4rem;
       margin-bottom:0;


       p {
        margin:.17rem  0 0 0;
        padding:0;
        font-size: .43rem;
        color: #fff;
        text-align: center;
       }

       .time {
         margin-top:.27rem;
         overflow: hidden;
         margin-left: 3.15rem;
         div {
           width: .72rem;
           height:.72rem;
           background: #FFFFFF;
           border-radius: .13rem;
           float: left;
           color: #181818;
           font-size: .47rem;
           text-align: center;
           line-height: .72rem;
         }

         img  {
           width: .09rem;
           height: .33rem;
           margin:0 .15rem;
           float: left;
           margin-top:.21rem;

         }
       }
     }

     .bottom {
       width: 9.2rem;
       height:5.67rem;
       background: #FFFFFF;
       border-radius: 0px 0px .13rem .13rem;
       overflow: hidden;
       margin-left: .4rem;


       p {
         margin:.53rem 0 .79rem 0;
         padding:0;
         font-size: .35rem;
         width: 100%;
         text-align: center;
         font-weight: 500;

         span {
           color: #FB6513;
         }
       }

       .photos  {
         height: 1.15rem;
         display: flex;
         justify-content:center;
         padding:0;
         margin:0;
         width: 100%;
         

         img {
           width:1.15rem ;
           height: 1.15rem;
           float: left;
           margin-left: .35rem;
           border-radius: 50%;
           overflow: hidden;
         }

         .dian {
           width: .71rem;
           height: .13rem;
           margin-left: .35rem;
           margin-top:.51rem;
           border-radius: 0;
         }
       }

       .btn  {
         width: 5.2rem;
         height:1.24rem;
         background: linear-gradient(97deg, #FEC752 0%, #FB8512 100%);
         border-radius: .27rem;
         margin:.8rem  0 0 1.99rem;
         font-size: .48rem;
         font-weight: bold;
         color: #FEFFFF;
         text-align: center;
         line-height:1.24rem;
       }
     }
  }

  .img {
    box-shadow:
       -3px 0 1px 0px #fff, /*左边阴影*/
       0 -3px 1px 0px #fff , /*顶部阴影*/
       0 4px 1px -1px #b9bec1, /*底部阴影*/
       4px 0 1px -1px  #b9bec1; /*右边阴影*/
    margin-left: .4rem;
    width: 9.2rem;
    border-radius: 0.33rem;
    overflow: hidden;
    margin-bottom:.27rem;

    img {
      width: 100%;
      height: 100%;
    }

  }
  .zhezhao  {
    width: 100%;
    height: 100%;
     background:rgba(0,0,0,0.3);
    position: absolute;
    top:0;
    left: 0;
  }

  .rule  {
    width: 6.53rem;
    background-color: #EBEFF2;
    overflow: hidden;
    position: absolute;
    top:50%;
    transform: translateY(-50%);
    left: 1.73rem;
    border-radius: .27rem;
    
    
    p {
     font-size:.37rem;
     font-weight: 500;
     color: #8C98B3;
     margin:.4rem 0;
     padding:0;
     text-align: center;
    }

    img {
      width: .35rem;
      height: .35rem;
      position: absolute;
      top:.28rem;
      right: .28rem;
    }

    .content  {
      font-size:.32rem;
      font-weight: 500;
      color: #8C98B3;
      margin:0 .47rem .67rem;
      line-height: 1.5;
    }
  }
}


</style>